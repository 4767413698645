<template>
  <div>
    <div class="mb-10">
      <div class="text-lg font-bold mb-5">
        Credit Card
      </div>
      <div v-if="staff.personal_account" class="flex mb-8">
        <button
          type="button"
          class="btn btn-sm btn-red align-middle"
          @click.prevent="openFreezeModal"
        >
          Freeze Card
        </button>
        <button
          type="button"
          class="btn btn-sm btn-green align-middle mx-4"
          @click.prevent="openUnfreezeModal"
        >
          Unfreeze Card
        </button>

        <button
          type="button"
          class="btn btn-sm btn-red align-middle"
          @click.prevent="openActivatePndModal"
        >
        Activate PND
        </button>
        <button
          type="button"
          class="btn btn-sm btn-green align-middle mx-4"
          @click.prevent="openDeactivatePndModal"
        >
          Deactivate PND
        </button>
        <!-- <button type="button" class="btn btn-sm btn-blue align-middle">
          Update Card Plan
        </button> -->
      </div>
      <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Credit Card Limit
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ creditCardAccountData("credit_card_limit") }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">
            Available Balance
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ creditCardAccountData("available_balance") }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Available Credit
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ creditCardAccountData("available_credit") }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">
            Utilization Rate
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="map-outline" class="text-lg mr-2"></ion-icon>
            {{ currentUtilization }}%
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Average Utilization Rate
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="medal-outline" class="text-lg mr-2"></ion-icon>
            {{ averageUtilization }}%
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">Card Plan</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon
              name="people-circle-outline"
              class="text-lg mr-2"
            ></ion-icon>
            {{ planName }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Card Plan Start Date
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
            {{ planStartDate }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">
            Card Plan End Date
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
            {{ planEndDate }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Account Number</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
            {{ creditCardAccountData("account_no") }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">
            Card Interest Rate
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
            {{ planInterest }}%
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Annual Plan Fee</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            {{ planFee }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">Status</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="calculator-outline" class="text-lg mr-2"></ion-icon>
            {{ creditCardAccountData("status") }}
          </div>
        </div>
      </div>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="freezeModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm Freeze</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeFreezeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="freezeCard"
        >
          <span v-if="loading">Submitting</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="UnfreezeModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm Unfreeze</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeUnfreezeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="UnfreezeCard"
        >
          <span v-if="loading">Submitting</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="activatePndModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm PND Activation</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeActivatePndModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="ActivatePnd"
        >
          <span v-if="loading">Activating</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="deactivatePndModal"
      @close="removeError"
    >
      <h4 class="text-xl font-bold mb-6">Confirm PND Deactivation</h4>

      <template v-if="requestError">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon">!</div>
          <span>{{ requestError }}</span>
        </div>
      </template>

      <div class="mb-6">Are you sure you want to continue?</div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-red-soft btn-md mr-2"
          @click.prevent="closeDeactivatePndModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="DeactivatePnd"
        >
          <span v-if="loading">Deactivating</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>

      <button
        type="button"
        class="button bg-blue-500 hover:bg-blue-600 text-white"
        @click.prevent="$refs.successModal.close()"
      >
        Okay
      </button>
    </modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      requestError: "",
      cardPlan: null,
      cardUtilization: null,
    };
  },
  computed: {
    bankStatements() {
      return this.staff?.bank_statements || [];
    },
    currentLoan() {
      return this.staff?.active_loans?.[0];
    },
    currentLoanType() {
      const loanType = this.currentLoan?.loan_type;

      switch (loanType) {
        case "standard":
          return "Standard Loan";
        case "pay_day":
          return "Pay Day Loan";
        default:
          return this.currentLoan?.loan_type || "None";
      }
    },
    currentLoanAmount() {
      return this.currentLoan?.loan_amount || 0;
    },
    lastBankStatement() {
      return this.bankStatements[this.bankStatements.length - 1];
    },
    position() {
      return this.staff?.profile ? this.staff?.profile.position : "Staff";
    },
    staffId() {
      return this.$route.params.staffId;
    },
    totalLoanAmount() {
      return (
        this.loans &&
        this.loans.reduce(
          (last, transaction) => last + Number(transaction.loan_amount),
          0
        )
      );
    },
    loans() {
      return this.staff?.loan || [];
    },
    loanHistory() {
      return this.creditHistory?.account_summary || [];
    },
    totalCreditLoans() {
      const writtenoff = this.loanCount("writtenoff");
      const open = this.loanCount("open");
      const closed = this.loanCount("closed");

      return writtenoff + open + closed;
    },
    hasStaffRole() {
      return this.staff?.roles?.find((role) => role.slug == "company_staff");
    },
    planName() {
      return this.cardPlan?.plan_name;
    },
    planFee() {
      return this.$options.filters.currency(this.cardPlan?.fee || 0);
    },
    planInterest() {
      return this.cardPlan?.interest;
    },
    currentUtilization() {
      return (this.cardUtilization?.utilization?.average || 0) * 100;
    },
    averageUtilization() {
      return (this.cardUtilization?.averageUtilization || 0) * 100;
    },
    planStartDate() {
      // const result = new Date(this.cardPlan?.start_date);
      return moment(this.cardPlan?.start_date).format("MMM Do YYYY");
    },
    planEndDate() {
      return moment(this.cardPlan?.start_date)
        .add(this.cardPlan?.duration, "days")
        .format("MMM Do YYYY");
    },
  },
  beforeMount() {
    this.getUserPlan();
    this.getUtilizationDetails();
  },
  methods: {
    removeError() {
      this.requestError = "";
    },
    openFreezeModal() {
      this.$refs.freezeModal.open();
    },
    closeFreezeModal() {
      this.$refs.freezeModal.close();
    },
    openUnfreezeModal() {
      this.$refs.UnfreezeModal.open();
    },
    closeUnfreezeModal() {
      this.$refs.UnfreezeModal.close();
    },
    openActivatePndModal() {
      this.$refs.activatePndModal.open();
    },
    closeActivatePndModal() {
      this.$refs.activatePndModal.close();
    },
    openDeactivatePndModal() {
      this.$refs.deactivatePndModal.open();
    },
    closeDeactivatePndModal() {
      this.$refs.deactivatePndModal.close();
    },
    loanCount(key) {
      const loan_counts = this.creditHistory?.total_summary?.loan_count;
      return loan_counts && loan_counts[key] ? loan_counts[key] : 0;
    },
    profileData(key, default_value = "N/A") {
      const data = this.staff?.profile?.[key];
      return data ? data : default_value;
    },
    creditCardAccountData(key, default_value = "N/A") {
      const data = this.staff?.personal_account?.[key];
      return data ? data : default_value;
    },
    async freezeCard() {
      this.loading = true;
      await this.$put({
        url: `${this.$baseurl}/admin/personal/cards/freeze`,
        data: {
          account_no: this.staff.personal_account.account_no,
          user_id: this.staff.id,
        },
        headers: this.headers,
        success: () => {
          this.closeFreezeModal();
          this.$refs.successModal.open();
        },
        error: () => {
          this.requestError = "there was an issue please try again later";
        },
      });
      this.loading = false;
    },
    async UnfreezeCard() {
      this.loading = true;
      await this.$put({
        url: `${this.$baseurl}/admin/personal/cards/unfreeze`,
        data: {
          account_no: this.staff.personal_account.account_no,
          user_id: this.staff.id,
        },
        headers: this.headers,
        success: () => {
          this.closeUnfreezeModal();
          this.$refs.successModal.open();
        },
        error: () => {
          this.requestError = "there was an issue please try again later";
        },
      });
      this.loading = false;
    },
    async ActivatePnd() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/activate-post-no-debit/${this.staff.id}`,
        headers: this.headers,
        success: () => {
          this.closeActivatePndModal();
          this.$refs.successModal.open();
        },
        error: () => {
          this.requestError = "there was an issue please try again later";
        },
      });
      this.loading = false;
    },
    async DeactivatePnd() {
      this.loading = true;
      await this.$post({
        url: `${this.$baseurl}/staff/deactivate-post-no-debit/${this.staff.id}`,
        headers: this.headers,
        success: () => {
          this.closeDeactivatePndModal();
          this.$refs.successModal.open();
        },
        error: () => {
          this.requestError = "there was an issue please try again later";
        },
      });
      this.loading = false;
    },
    async getUtilizationDetails() {
      if (!this.staff.personal_account) {
        return;
      }
      this.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/${this.staff.personal_account.id}/utilization/${this.staff.id}`,
        headers: this.headers,
        success: (response) => {
          this.cardUtilization = response.data.data;
        },
        error: () => {
          this.requestError = "there was an issue please try again later";
        },
      });
      this.loading = false;
    },
    async getUserPlan() {
      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/${this.staff.id}/user-plan`,
        headers: this.headers,
        success: (response) => {
          this.cardPlan = response.data.data;
        },
        error: () => {
          this.requestError = "there was an issue please try again later";
        },
      });
    },
  },
};
</script>
